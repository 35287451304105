import clsx from 'clsx/lite'

const Container = ({ as, children, className, wrapperClassName = 'bg-gray-100' }: { as?: React.ElementType; children: React.ReactNode; className?: string; wrapperClassName?: string }) => {
  const Component = as || 'div'
  return (
    <Component className={clsx(wrapperClassName)}>
      <div className={clsx('mx-auto max-w-[1440px] px-4 lg:px-6', className)}>{children}</div>
    </Component>
  )
}

export default Container
